.GroupCard__card {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 7.375rem;
  border-radius: 1rem;
  border-color: blue;

  background-color: rgb(var(--primary));
  overflow: hidden;

  text-decoration: none;

  transition: all 0.15s linear;
}

.GroupCard__card-minimal {
  height: auto;
}

.GroupCard__card:hover {
  filter: brightness(95%);
}

.GroupCard__layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.GroupCard__class-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-weight: 500;
}

.GroupCard__text-right {
  text-align: right;
}

.GroupCard__join-code {
  color: rgb(255, 255, 255);
}
