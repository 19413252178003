.InfoModal__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0px;
  gap: 60px;
}

.InfoModal__header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}

.InfoModal__section {
  display: flex;
  width: 75%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.InfoModal__section-text {
  max-width: 350px;
}
