.Calendar__main-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}

.Calendar__legend-div {
  display: flex;
  flex-direction: column;
}

.Calendar__dates-container{
  flex:1
}

.Calendar__left {
  padding: 10px;
  flex: 0.7;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgb(var(--primary));
}

.Calendar__main {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Calendar__header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
}

.Calendar__calendar-bottom {
  max-height: 300px;
  max-width: 100%;
}

.Calendar__scroll-ctn {
  overflow-y: scroll;
}


