.AuthForm__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 60%;
  height: 80%;
  max-height: 38rem;
  border-radius: 0.5rem;
  background-color: #fff;
  overflow: hidden;
}

.AuthForm__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.AuthForm__logo {
  height: 2rem;
  margin-left: auto;
}

.AuthForm__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 40%;
  padding: 2rem;
  text-align: center;
  color: white;
  background: -webkit-linear-gradient(
    left top,
    rgb(var(--primary)),
    rgb(var(--light-blue))
  );
  background: -o-linear-gradient(
    bottom right,
    rgb(var(--primary)),
    rgb(var(--light-blue))
  );
  background: -moz-linear-gradient(
    bottom right,
    rgb(var(--primary)),
    rgb(var(--light-blue))
  );
  background: linear-gradient(
    to bottom right,
    rgb(var(--primary)),
    rgb(var(--light-blue))
  );
}

.AuthForm__right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  overflow-y: auto;
}

.AuthForm__form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 26rem;
}

.AuthForm__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.AuthForm__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.AuthForm__form-btns {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.AuthForm__nav-link {
  display: none;
}

@media only screen and (max-width: 992px) {
  .AuthForm__left {
    display: none;
  }

  .AuthForm__nav-link {
    display: block;
  }
}

@media (max-width: 768px) {
  .AuthForm__container {
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;
  }

  .AuthForm__left {
    display: flex;
  }

  .AuthForm__nav-link {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .AuthForm__left {
    display: none;
  }

  .AuthForm__nav-link {
    display: block;
  }
}
