.PasswordReset__form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 35%;
  max-width: 500px;
  padding: 32px 64px;
  border-radius: 8px;
  background-color: #fff;
}

.PasswordReset__bottom-btns {
  display: flex;
  gap: 2rem;
}

.PasswordReset__form input {
  width: 100%;
}

#PasswordReset__closed {
  display: none;
}
