.PercentDisplay__container {
  display: flex;
  justify-content: stretch;
  gap: 1rem;
  max-width: 33%;
}

.PercentDisplay__data {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.PercentDisplay__header {
  display: flex;
  justify-content: space-between;
}

.PercentDisplay__progress-container {
  width: 100%;
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: rgb(var(--secondary));
}

.PercentDisplay__percent-bar {
  width: 0;
  height: 100%;
  border-radius: 0.5rem;
  background-color: rgb(var(--primary));
  transition: all 0.15s;
}
