.CalendarClassOval__container {
  width: 100%;

}

.CalendarClassOval__button {
  height: fit-content;
  width: 100%;
  align-items: center;
  gap: 5px;
  border-radius: 7.5px;
  padding: 3px;
}
