.Question__question-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem 1rem;
  border: none;
  border-radius: 1rem;
  background-color: rgb(var(--secondary));
  color: rgba(0, 0, 0, 0.8);
  transition: all 0.15s linear;
}

.Question__selected {
  background-color: rgb(var(--primary));
  color: white;
}
