.TopicDisplay__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.TopicDisplay__header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 1rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.TopicDisplay__topic-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.TopicDisplay__question-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.TopicDisplay__additional-feedback-header {
  margin-bottom: 1rem;
}

.TopicDisplay__additional-feedback-list {
  list-style-type: none;
}

.TopicDisplay__additional-response-card {
  background-color: rgb(var(--secondary));
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
}
