.ClassLegend__container {
  max-height: 25vh;
  overflow: scroll;
  overflow-x: hidden;
  width: 100%;
}

.ClassLegend__classes-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}
.ClassLegend__legend-dot {
  width: 20px;
  height: 20px;
  opacity: 0.8;
  border: 1px solid white;
}

.ClassLegend__class-name {
  color: black;
  max-width: 60%;
  overflow: hidden;
}

.ClassLegend__legend-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
