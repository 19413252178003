.CalendarDate__date-container {
  width: 100%;
  height: 130px;
  border-top: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
  position: relative;
}
.CalendarDate__date-container:hover {
  background-color: rgb(var(--secondary));
}

.CalendarDate__topic-container {
  height: fit-content;
  width: 100%;
  align-items: center;
  gap: 5px;
  border-radius: 4px;
  padding: 3px;
}

.CalendarDate__topic {
  color: white;
  font-weight: 500;
}

.CalendarDate__topics {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.CalendarDate__corner-button {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 10%;
  height: 20%;
  border-radius: 0 0 0.5rem 0.5rem; /* Bottom two corners rounded */
  background-color: rgb(var(--primary));
  color: white;
  font-weight: 600;
  text-decoration: none;
}
