.PopupModal__container {
  position: absolute;
}

.PopupModal__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.4;
  z-index: 1000;
}

.PopupModal__modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 32rem;
  max-height: 36rem;
  padding: 4rem 1rem;
  border-radius: 1rem;

  background-color: white;
  z-index: 1000;
}

.PopupModal__scrollable {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding: 0 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.PopupModal__close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  background-color: transparent;
  opacity: 0.8;
}
