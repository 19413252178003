.CalendarDays__container {
  width: 100%;
  height: 100%;
}

.CalendarDays__calendar-table {
  width: 100%;
  table-layout: fixed; /* Force equal column widths */
  border-collapse: collapse; /* Remove spacing between cells */
}

.CalendarDays__row {
  width: 100%;
  display: flex;
}
.CalendarDays__item {
  width: calc(100% / 7);

  box-sizing: border-box; /* Include padding and border in the width calculation */
}

.CalendarDays__header-box {
  width: calc(100% / 7);
  text-align: center;
  background-color: rgb(var(--primary));
}
.CalendarDays__header {
  color: white;
}


.CalendarDays__weekday-header{
  width: 100%;
  display: flex;

  border-radius: 3px;
}