.Chat__chat-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 0 3rem 0;
}

.Chat__chat-header,
.Chat__chat-header-fullscreen {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 2.5rem;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.Chat__chat-header {
  justify-content: space-between;
}

.Chat__chat-header-fullscreen {
  justify-content: flex-start;
  gap: 1rem;
  background-color: rgb(var(--primary));
  color: white;
}

.Chat__chat-title-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.Chat__chat-list {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem;
  overflow-y: auto;
}

.Chat__loader {
  position: relative;
  width: 100%;
  height: 4rem;
  min-height: 4rem;
  margin-bottom: 1rem;
}

.Chat__date-line {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.Chat__date-line div {
  flex: 1;
  height: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.Chat__date-line p {
  opacity: 0.5;
}

.Chat__no-messages-msg {
  margin: 0 auto;
  text-align: center;
  opacity: 0.8;
}

.Chat__sendMessageForm {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 0 1rem 1rem 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(16px);
}

.Chat__sendMessageForm textarea {
  flex: 1;
  resize: none;
}

.Chat__send-btn {
  position: absolute;
  right: 0;
  transform: translateX(-50%);
  background: transparent !important;
}

.Chat__chat-toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
