.Loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
  border: 4px solid;
  border-color: rgb(var(--primary)) rgb(var(--primary)) rgb(var(--primary))
    transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite forwards;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
