.IconButton__btn {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2rem;
  min-width: 2rem !important;
  height: 2rem;
  min-height: 2rem !important;
  border: none;
  border-radius: 50%;
}
