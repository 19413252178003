.BarChart__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.BarChart__title {
  margin: 0 auto 1rem 0;
}

.BarChart__top {
  display: flex;
  width: 100%;
}

.BarChart__y-axis {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-end;
  color: rgba(0, 0, 0, 0.6);
}

.BarChart__data {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  align-items: end;
  width: 100%;
  height: calc(100% - 1rem + 1px);
  margin: 0.5rem 0 0.5rem 0.75rem;
}

.BarChart__x-axis {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  width: calc(100% - 2rem);
  margin: -0.5rem 0 0 2rem;
  padding-top: 1rem;
  color: rgba(0, 0, 0, 0.6);
}

.BarChart__x-axis p {
  text-align: center;
}

.BarChart__bar-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.BarChart__hover {
  position: absolute;
  transform: translate(50%, 50%);
  left: 50%;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: rgb(var(--secondary));
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s linear;
}

.BarChart__hover::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 0.5rem;
  height: 0.5rem;
  background-color: rgb(var(--secondary));
}

.BarChart__bar:hover + .BarChart__hover,
.BarChart__hover:hover {
  opacity: 1;
  visibility: visible;
}

.BarChart__bar {
  background-color: rgb(var(--primary));
  width: 3rem;
  height: 0;
  margin: 0 auto;
  border-radius: 0.25rem 0.25rem 0 0;
  transition: all 0.15s linear;
}
