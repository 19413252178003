.TopicAnalyticsAccordion__accordion-root {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

.TopicAnalyticsAccordion__accordion-header {
  display: flex;
}

.TopicAnalyticsAccordion__accordion-trigger {
  flex: 1;
  background-color: rgb(var(--secondary));
  text-align: left;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
}

.TopicAnalyticsAccordion__accordion-trigger:hover {
  filter: brightness(95%);
}

.TopicAnalyticsAccordion__accordion-trigger[data-state="open"] {
  filter: brightness(95%);
}

.TopicAnalyticsAccordion__accordion-content {
  overflow: hidden;
  padding: 1.5rem 1rem;
}

.TopicAnalyticsAccordion__accordion-content-divider {
  margin: 1rem 0;
}

.TopicAnalyticsAccordion__participation {
  display: flex;
  justify-content: space-between;
}

.TopicAnalyticsAccordion__understanding {
  display: flex;
  justify-content: space-between;
}

.TopicAnalyticsAccordion__understanding > div {
  text-align: right;
}
