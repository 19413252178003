.Classes__container {
  flex: 1;
  display: flex;
}

.Classes__class-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  max-height: 100vh;
  padding: 2rem;
  overflow-y: auto;
}

.Classes__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid #ccc;
}

.Classes__university-name {
  text-transform: capitalize;
}

.Classes__add-class-msg {
  margin: auto;
  padding-top: 1rem;
  opacity: 0.8;
}

.Classes__class-list {
  display: flex;
  flex-flow: column;
  gap: 2rem;
}

.Classes__schedule {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  width: 30%;
  height: 100vh;
  padding: 2rem;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}

.Classes__no-classes-msg {
  margin: 0 auto;
  padding-top: 1rem;
  text-align: center;
  opacity: 0.8;
}

#Classes__dayTimeContainer {
  width: 100%;
}

.Classes__header-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
