.Login__form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 35%;
  max-width: 500px;
  padding: 32px 64px;
  border-radius: 8px;
  background-color: #fff;
}

.Login__form input {
  width: 100%;
}

.auth-logo {
  max-height: 6rem;
  width: 100%;
}

.Login__signup-text {
  color: rgba(0, 0, 0, 0.5) !important;
  text-align: center;
}

.Login__link {
  color: rgba(0, 0, 0, 0.5);
}

.Login__password-field {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.Login__forgot-password:hover {
  text-decoration: underline;
}
@media (max-width: 820px) {
  .Login__form {
    width: 70%;
    height: 70%;
  }

  .Login__form input {
    width: 100%;
    font-size: 20px;
  }

  .Login__signup-text {
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 20px;
  }

  .Login__link {
    color: rgba(0, 0, 0, 0.5);
    font-size: 17px;
  }
}

@media (max-width: 767px) {
  .Login__form {
    width: 100%;
    height: 100%;
  }
}
