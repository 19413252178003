/* ClassDateModal Component-wide CSS Classes /*/
.ClassDateModal__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
}

.ClassDateModal__topics-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 5px;
}

.ClassDateModal__questions-container {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.ClassDateModal__topic-oval {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid black;
  padding: 10px;
}

.ClassDateModal__select-question {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ClassDateModal-input-form {
  width: 100%;
}

.ClassDateModal__select {
  width: 370px;
  min-width: 370px;
  max-width: 370px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 40px;
}

/* Element-specific CSS Classes */

/* Groups CSS Classes */

.AdminModalScheduler__groups-div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.AdminModalScheduler__questions-div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.groups-header {
  margin-top: 15px !important;
}

.groups-info-icon {
  margin-bottom: 15px;
  margin-left: 5px;
}

.groups-header-row {
  margin-left: 60px;
  display: flex;
  align-items: center;
}

/* Questions CSS Classes */
.questions-header-row {
  margin-left: 60px;
  display: flex;
  align-items: center;
}

.weekly-button {
  color: rgb(155, 155, 155);
}

.weekly-button:hover {
  color: rgb(var(--primary));
}

/* Question CSS Classes */
.dropdown-menu {
  margin-top: 10px !important;
  width: 100%;
}

.add-question-text {
  color: rgb(155, 155, 155);
  margin-top: 30px;
  margin-left: -10px;
}
.add-question-text:hover {
  color: rgb(var(--primary));
  text-decoration: underline;
}

.questions-header {
  margin-top: 30px !important;
}

.question-header-row {
  margin-left: 40px;
  display: flex;
  align-items: center;
}

.question-info-icon {
  margin-bottom: -30px;
  margin-left: 5px;
}

.question-remove-button {
  position: absolute;
  top: 5px; /* Adjust as needed for alignment */
  right: 10px; /* Adjust as needed for alignment */
  cursor: pointer; /* To indicate it's clickable */
}

.question-remove-button:hover {
  background-color: rgb(187, 187, 192) !important;
}
.question-input {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  outline: none !important;
  margin-bottom: 20px !important;
  width: 90% !important;
  line-height: 1.5 !important;
  font-weight: 600;
  padding-bottom: 0px !important;
}
.question-input:hover {
  border-bottom: 1px solid rgb(187, 187, 192) !important;
}

.question-input:focus {
  border-bottom: 2px solid rgb(var(--primary)) !important;
}

.AdminModalScheduler__add-option-text {
  color: rgb(155, 155, 155);
  text-align: center;
}
.AdminModalScheduler__add-option-text:hover {
  color: rgb(var(--primary));
  text-decoration: underline;
}

.option-row-admin-modal {
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 98.5%;
}

.option-input {
  background-color: transparent !important; /* For a transparent background */
  border: none !important; /* Removes the border */
  border-radius: 0 !important;
  outline: none !important; /* Optional: Removes the outline on focus */
  margin-bottom: 20px !important;
  width: 315px !important;
}
.option-input:hover {
  border-bottom: 1px solid rgb(187, 187, 192) !important;
}

.option-input:focus {
  border-bottom: 2px solid rgb(var(--primary)) !important;
}

.option-remove-button {
  margin-top: -30px; /* Adjust the value as needed */
}

.option-remove-button:hover {
  background-color: rgb(187, 187, 192) !important;
}

.AdminModalScheduler__tag-input {
  background-color: transparent;
  border-radius: 2px !important;
  outline: none !important; /* Optional: Removes the outline on focus */
  margin-bottom: 20px !important;
  width: 315px !important;
}

.input-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ClassLegend__legend-dot {
  width: 20px;
  height: 20px;
  opacity: 0.8;
  border: 1px solid white;
  cursor: pointer;
}

.tags-row {
  display: flex;
  align-items: center;
}

.tag-item {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  width: 150px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: rgb(var(--primary));
}
