.ScheduleCard__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  padding: 1rem;
  border-radius: 1rem;

  background-color: rgb(var(--secondary));
}

.ScheduleCard__header {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.ScheduleCard__go-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.375rem 1rem;
  border-radius: 0.5rem;

  background-color: rgb(var(--primary));
  color: white;
  text-decoration: none;
}
