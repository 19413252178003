.Dashboard__container {
  display: flex;
  height: 100vh;
  position: relative; /* Set relative positioning on the container */
}

.Dashboard__data-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 100vh;
  padding: 2rem;
  overflow-y: auto;
}

.Dashboard__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
}
.Dashboard__header-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.GroupDashboard__questions {
  display: flex;
  flex-direction: column;
  gap: 7px;
  overflow-y: scroll;
}
.GroupDashboard__dashboard-middle {
  display: flex;
  max-height: 50vh; /* Limits height to 70% of the viewport height */

  flex-direction: row;
  gap: 20px;
}

.GroupDashboard__dashboard-left {
  flex: 1;
  max-width: 60%; /* Limits width to 70% of the viewport width */
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.GroupDashboard__dashboard-right {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: scroll;
  width: 40%;
}

.GroupDashboard__dashboard-bottom {
  flex: 1;
  max-width: 100%; /* Limits width to 70% of the viewport width */
  max-height: 50vh; /* Limits height to 70% of the viewport height */
  padding: 10px;
}

.GroupDashboard__repeating {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.GroupDashboard__scheduled-dates {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.GroupDashboard__emptymodal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left; /* Optional: Center text within the element */
  padding: 10px;
  width: 40%;
  height: 40%;
  border-radius: 20px;
  border: 4px solid black;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  background-color: white;
}

.DashboardGroup__task-done {
  color: green;
}

.DashboardGroup__task-not-done {
  color: red;
}

.GroupDashboard-response-card {
  background-color: rgb(var(--secondary));
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
}

.GroupDashboard__question-labels {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
}
.GroupDashboard__question-label {
  display: flex;
  align-items: center;
  justify-content: center;
}


.GroupDashboard__search-div{
  display: flex;
  flex-direction: row;

}
