.AnalyticsClassList__container {
  padding: 2rem;
}

.AnalyticsClassList__divider {
  margin: 2rem 0;
}

.AnalyticsClassList__class-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
