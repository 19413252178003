#ToggleInput__checkbox {
  display: none;
}

.ToggleInput__background {
  position: relative;
  display: block;
  width: 2rem;
  height: 1rem;
  padding: 0.125rem;
  background-color: rgb(var(--secondary));
  border-radius: 0.5rem;
  cursor: pointer;
}

#ToggleInput__checkbox:checked + .ToggleInput__background {
  background-color: rgb(var(--primary));
}

.ToggleInput__background::after {
  content: "";
  position: absolute;
  top: 0.0625rem;
  left: 0.0625rem;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: 0.15s all linear;
}

#ToggleInput__checkbox:checked + .ToggleInput__background::after {
  left: calc(100% - 0.0625rem);
  transform: translateX(-100%);
}
