.SideModal__container {
    position: absolute;
  }
  
  .SideModal__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.4;
    z-index: 1000;
  }
  
  .SideModal__modal {
    position: fixed;
    top: 50%;
    right: 0; 
    transform: translateY(-50%);

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 32rem;
    height: 100%;
    padding: 4rem 1rem;
    border-radius: 1rem;

    background-color: white;
    z-index: 1000;
}
  
  .SideModal__scrollable {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 0 1rem;
    overflow: auto;
  }
  
  .SideModal__close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
    background-color: transparent;
    opacity: 0.8;
  }
  