.UserCard__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: rgb(var(--secondary));
}

.UserCard__role {
  text-transform: capitalize;
}
