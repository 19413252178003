.GroupClassModal__modal-contents {
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.AddClassModal__modal-contents {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.AddClassModal__modal-tab {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding-top: 2rem;
}

.AddClassModal__join-code-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.AddClassModal__form-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 3rem;
  border: none;
  border-radius: 0.5rem;
  background-color: rgb(var(--primary));
  color: white;
}

.AddClassModal__create-class-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.AddClassModal__create-class-form input {
  width: 100%;
}

.AddClassModal__add-time-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.AddClassModal__async-toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.AddClassModal__time-selector {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.AddClassModal__time-and-add {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.AddClassModal__time-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.AddClassModal__select {
  width: 100%;
}

.AddClassModal__bottom-btns {
  display: flex;
  gap: 2rem;
}

.AddClassModal__class-duration {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.AddClassModal__eval-questions-selection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddClassModal__selected-eval-question-div {
  border: 1px black solid;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
}


.GroupClassModal__selected{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 2rem;
  padding: 0 2rem;
  border: none;
  border-radius: 0.5rem;
  background-color: rgb(var(--primary));
  color: white;
  font-weight: 600;
  text-decoration: none;
}

.GroupClassModal__unselected{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 2rem;
  padding: 0 2rem;
  border: 2px rgb(160, 160, 160) solid;
  border-radius: 0.5rem;
  background-color: white;
  color: black;
  font-weight: 600;
  text-decoration: none;
}