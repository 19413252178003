.AppStoreRedirect__center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 35%;
  max-width: 500px;
  padding: 32px 64px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 32px -6px rgba(0, 0, 0, 0.25);
}

.AppStoreRedirect__logo {
  width: 100%;
}

.AppStoreRedirect__prompt {
  text-align: center;
}

@media (max-width: 820px) {
  .AppStoreRedirect__container {
    width: 70%;
    height: 70%;
  }
}

@media (max-width: 767px) {
  .AppStoreRedirect__container {
    width: 100%;
    height: 100%;
  }
}
