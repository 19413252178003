.DateInput__empty,
.DateInput__full {
  position: relative;
  appearance: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100% !important;
  height: 2rem !important;
  transition: none !important;
}

.DateInput__empty::-webkit-date-and-time-value,
.DateInput__full::-webkit-date-and-time-value {
  text-align: left !important;
}

.DateInput__empty {
  color: transparent !important;
}

.DateInput__empty::before {
  content: attr(placeholder) !important;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  background: transparent !important;
}
