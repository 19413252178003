.TimeCard__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  height: 2rem;
}

.TimeCard__info {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  padding: 0.3125rem 0.5rem;
  border-radius: 0.5rem;

  background-color: rgb(var(--secondary));
}

.TimeCard__times {
  text-transform: lowercase;
}
