.Dashboard__container {
  display: flex;
}

.Dashboard__data-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 100vh;
  padding: 2rem;
  overflow-y: auto;
}

.Dashboard__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Dashboard__header-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Dashboard__header-right-btns {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.Dashboard__class-name {
  display: flex;
  align-items: center;
  height: 37.5px;
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
}

.Dashboard__topic-display {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Dashboard__chat,
.Dashboard__chat-large {
  position: sticky;
  top: 0;
  width: 30%;
  height: 100vh;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.Dashboard__chat-large {
  position: relative;
  flex: 1;
  border: none;
}

.Dashboard__survey-btn {
  position: absolute;
  top: 1rem;
  right: 2rem;
}

.Dashboard__survey {
  width: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transition: width 0.1s ease-in-out;
  overflow: hidden;
}

.Dashboard__survey-header {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

#Dashboard__participation {
  width: 50%;
}

#Dashboard__toggleBar {
  width: 25%;
}
