.AdminDisplay__ctn {
  display: flex;
  flex-direction: row;
}

.AdminDisplay__chart-ctn {
  flex: 1;
}

.AdminDisplay__chart-settings {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 0.4;
  padding: 5px;
}

.AdminDisplay__chart-toggles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2%;
}

.admin-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 2rem;
  padding: 0 2rem;
  border: none;
  border-radius: 0.5rem;
  color: black;
  font-weight: 600;
  text-decoration: none;
}

.AdminDisplay__right {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
}
