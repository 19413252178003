.ClassSettings__container {
  display: flex;
}

.ClassSettings__modal-contents {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.ClassSettings__modal-btns {
  display: flex;
  gap: 2rem;
}

.ClassSettings__class-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 100vh;
  padding: 2rem;
  overflow-y: auto;
}

.ClassSettings__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #ccc;
}

.ClassSettings__header-left {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.ClassSettings__join-code {
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  background-color: rgb(var(--secondary));
}

.ClassSettings__settings-form {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
}

.ClassSettings__save-btn {
  margin-left: auto;
}

.ClassSettings__schedule {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ClassSettings__enrolled-students {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  width: 30%;
  height: 100vh;
  padding: 2rem;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}
