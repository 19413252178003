.CodeForm__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.CodeForm__input {
  width: 4rem !important;
  height: 6rem;
  border: none !important;
  border-radius: 0.5rem !important;
  background-color: rgb(var(--secondary));
  line-height: 6rem;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}
