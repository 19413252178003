.ChatMessage__message,
.ChatMessage__message-user-owned {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 80%;
}

.Chat__btn {
  border: none;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-align: left;
  text-decoration: none;
  outline: none;
}

.ChatMessage__message-user-owned {
  margin-left: auto;
}

.ChatMessage__message-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.ChatMessage__message-user-owned .ChatMessage__message-info {
  flex-direction: row-reverse;
}

.ChatMessage__user-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  color: white;
}

.ChatMessage__message-content {
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  background-color: rgb(var(--secondary));
  color: black;
}

.ChatMessage__message-user-owned .ChatMessage__message-content {
  background-color: rgb(var(--primary));
  color: white;
  margin-left: auto;
}

.ChatMessage__message-row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.ChatMessage__message-user-owned .ChatMessage__message-row {
  flex-direction: row;
}
