.AccountSettings__container {
  flex: 1;
  display: flex;
}

.AccountSettings__center {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  max-height: 100vh;
  padding: 2rem;
  overflow-y: auto;
}

.AccountSettings__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid #ccc;
}

.AccountSettings__settings-form {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
}

.AccountSettings__save-btn {
  margin-left: auto;
}

.AccountSettings__university {
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: rgb(var(--secondary));
  text-transform: capitalize;
}

.AccountSettings__feedback-form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}

.AccountSettings__feedback-input {
  width: 100%;
  padding: 1rem;
  resize: none;
}

.AccountSettings__registered {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  width: 30%;
  height: 100vh;
  padding: 2rem;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}

.AccountSettings__class-list {
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
}
