.ClassCard__card {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 7.375rem;
  border-radius: 1rem;

  background-color: rgb(var(--secondary));
  overflow: hidden;

  text-decoration: none;

  transition: all 0.15s linear;
}

.ClassCard__card-minimal {
  height: auto;
}

.ClassCard__card:hover {
  filter: brightness(95%);
}

.ClassCard__layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.ClassCard__class-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  color: black;
  text-decoration: none;
  font-weight: 500;
}

.ClassCard__text-right {
  text-align: right;
}

.ClassCard__join-code {
  color: black;
}
