
/* ClassDateModal Component-wide CSS Classes /*/
.ClassDateModal__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
}

.ClassDateModal__topics-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 5px;

}
.ClassDateModal__questions-container {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.ClassDateModal__topic-oval {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid black;
  padding: 10px;
}

.ClassDateModal__select-question {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ClassDateModal-input-form {
  width: 100%;
}

.ClassDateModal__select {
  width: 370px;
  min-width: 370px;
  max-width: 370px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 40px;
}



/* Element-specific CSS Classes */

/* Topics CSS Classes */
.topics-header {
    margin-bottom: 15px !important;
}

.add-topic-text {
    color: rgb(155, 155, 155); 
    margin-top: 30px;
    margin-left: -10px;
}
  
.add-topic-text:hover {
    color: rgb(130, 130, 230);
    text-decoration: underline;
}

.topic-input {
    background-color: transparent !important; /* For a transparent background */
    border: none !important; /* Removes the border */
    border-radius: 0 !important;
    outline: none !important; /* Optional: Removes the outline on focus */
    margin-bottom: 20px !important;
    margin-left: 25px !important;
    width: 300px !important;
}
.topic-input:hover {
    border-bottom: 1px solid rgb(187, 187, 192) !important;
    border-bottom: 1px solid rgb(218, 218, 38)important;
}

.topic-input:focus {
    border-bottom: 2px solid rgb(22, 22, 246) !important;
}

.topic-item-row {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.topic-header-row {
    
    margin-left: 60px;
    display: flex;
    align-items: center;
}

.topic-remove-button {
    margin-top: -30px; /* Adjust the value as needed */
}

.topic-remove-button:hover {
    background-color: rgb(187, 187, 192) !important;
}

.topic-info-icon {
    margin-bottom: 15px;
    margin-left: 5px;
}


/* Question CSS Classes */
.dropdown-menu {
    margin-top: 10px !important;
    width:100%;
}

.add-question-text {
    color: rgb(155, 155, 155); 
    margin-top: 30px;
    margin-left: -10px;
}
.add-question-text:hover {
    color: rgb(130, 130, 230);
    text-decoration: underline;
}

.questions-header {
    margin-top: 30px !important;
}

.question-header-row {
    margin-left: 40px;
    display: flex;
    align-items: center;
}

.question-info-icon {
    margin-bottom: -30px;
    margin-left: 5px;
}

.question-remove-button {
  position: absolute;
  top: 5px;  /* Adjust as needed for alignment */
  right: 10px; /* Adjust as needed for alignment */
  cursor: pointer; /* To indicate it's clickable */
}
.question-remove-button:hover {
    background-color: rgb(187, 187, 192) !important;
}
.question-input {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    outline: none !important;
    margin-bottom: 20px !important;
    width: 90% !important;
    line-height: 1.5 !important;
    padding-bottom: 0px !important;
}
.question-input:hover {
    border-bottom: 1px solid rgb(187, 187, 192) !important;
}

.question-input:focus {
    border-bottom: 2px solid rgb(22, 22, 246) !important;
}

.add-option-text {
    color: rgb(155, 155, 155); 
    margin-left: -25px;
}
.add-option-text:hover {
    color: rgb(130, 130, 230);
    text-decoration: underline;
}


.option-row {
    display: flex;
    align-items: center;
}


.option-input {
    background-color: transparent !important; /* For a transparent background */
    border: none !important; /* Removes the border */
    border-radius: 0 !important;
    outline: none !important; /* Optional: Removes the outline on focus */
    margin-bottom: 20px !important;
    width: 315px !important;
}
.option-input:hover {
    border-bottom: 1px solid rgb(187, 187, 192) !important;
    border-bottom: 1px solid rgb(218, 218, 38)important;
}

.option-input:focus {
    border-bottom: 2px solid rgb(22, 22, 246) !important;
}


.option-remove-button {
    margin-top: -30px; /* Adjust the value as needed */
}

.option-remove-button:hover {
    background-color: rgb(187, 187, 192) !important;
}


.input-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}