.TabSwitcher__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.TabSwitcher__switcher {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  padding: 0.125rem;
  background-color: rgb(var(--secondary));
  border-radius: 0.5rem;
}

.TabSwitcher__tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.125rem 1rem;
  border: none;
  background-color: transparent;
  opacity: 0.8;
  white-space: nowrap;
}

.TabSwitcher__selected {
  border-radius: 0.375rem;
  background-color: rgb(var(--primary));
  color: white;
  opacity: 1;
}

.TabSwitcher__tab-label {
  font-weight: 700;
}
