@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf");
}

:root {
  --primary: 51, 51, 204;
  --secondary: 229, 231, 240;
  --accent: 255, 192, 38;
  --red: 255, 111, 111;
  --light-blue: 0, 191, 255;

  --toastify-color-progress-light: rgb(var(--primary));
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter;
  font-style: normal;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: Inter, Arial, Helvetica, sans-serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: black;
  overflow-x: hidden;
}

button,
input[type="button"] {
  cursor: pointer;
  transition: all 0.2s ease-in;
}

button:disabled,
input[type="button"]:disabled {
  opacity: 0.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
textarea {
  outline-color: rgb(var(--primary));
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="time"],
input[type="date"],
.DateInput__empty::after,
input[type="number"],
textarea {
  width: 100%;
  padding: 0.375rem 0.5625rem;
  border: none;
  border-radius: 0.5rem;
  background-color: rgb(var(--secondary));
  color: black;
  transition: all 0.15s linear;
}

::placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
  opacity: 1;
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
  opacity: 1;
}

:-moz-placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
  opacity: 1;
}

::-moz-placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
  opacity: 1;
}

::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
  opacity: 1;
}

input:disabled,
.Select--is-disabled {
  opacity: 0.5;
}

/* Width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(var(--secondary));
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(var(--primary), 0.8);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(var(--primary));
}

.btn {
  padding: 8px 32px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: white;
  color: black;
  font-weight: 600;
  min-height: 37.5px;
}

.cta-btn,
.cta-btn-red,
.cta-btn-gray,
.cta-btn-white {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 2rem;
  padding: 0 2rem;
  border: none;
  border-radius: 0.5rem;
  background-color: rgb(var(--primary));
  color: white;
  font-weight: 600;
  text-decoration: none;
}

.cta-btn-red {
  background-color: #ff6f6f;
}

.cta-btn-gray {
  background-color: rgb(var(--secondary));
  color: rgba(0, 0, 0, 0.8);
}

.cta-btn-white {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
}

.link-btn {
  border: none;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
  background-color: transparent;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.Select__control {
  min-height: auto !important;
  border: none !important;
  border-radius: 0.5rem !important;
  background-color: rgb(var(--secondary)) !important;
  cursor: pointer !important;
}

.Select__value-container {
  padding: 0 0.5rem !important;
}

.Select__single-value {
  color: black !important;
}

.Select__input-container {
  margin: 0 !important;
  padding: 0 !important;
}

.Select__placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}

.Select__indicator {
  padding: 0.375rem !important;
}

.Select__indicator svg {
  fill: rgba(0, 0, 0, 0.8) !important;
}

.Select__indicator-separator {
  background-color: rgba(0, 0, 0, 0.8) !important;
  margin: 0.375rem 0 !important;
}

.header-1,
h1 {
  font-size: 1.75rem;
  font-weight: 700;
}

.header-2,
h2 {
  font-size: 1.25rem;
  font-weight: 700;
}

.header-3,
h3 {
  font-size: 1.125rem;
  font-weight: 600;
}

.header-4,
h4 {
  font-size: 1rem;
  font-weight: 600;
}

.text-large {
  font-size: 1.125rem;
  font-weight: 400;
}

.text-medium,
p {
  font-size: 1rem;
  font-weight: 400;
}

.text-small {
  font-size: 0.875rem;
  font-weight: 400;
}

.text-gray {
  color: rgba(0, 0, 0, 0.5);
}

.text-center {
  text-align: center;
}

.no-underline {
  text-decoration: none;
}

.auth-container {
  width: 100vw;
  height: 100vh;
  background-color: rgb(var(--secondary));
  overflow-y: auto;
}
