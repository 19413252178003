.Analytics__container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  min-height: 100vh;
}

.Analytics__main-section {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.Analytics__main-section-header {
  display: flex;
  justify-content: space-between;
}

.Analytics__back-btn {
  font-weight: 500;
  color: white;
  background-color: rgb(var(--primary));
  border: none;
  border-radius: 8px;
  padding: 0.25rem 0.5rem;
}

.Analytics__graph-title {
  margin-bottom: 2rem;
}
