.AdminQuestion__btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  text-align: left;
}

.selected {
  background-color: rgb(var(--primary));
  color: white;
}

.AdminQuestion__question {
  flex: 0.6;
  height: 60px;
  display: flex;
  flex-wrap: wrap; /* Allow text to wrap */
  overflow: hidden; /* Hide overflow */
}

.AdminQuestion__question-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show before ellipsis */
  -webkit-box-orient: vertical;
}

.AdminQuestion__earliestDate {
  flex: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AdminQuestion__dates {
  flex: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AdminQuestion__lastScheduled {
  flex: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AdminQuestion__participation-div {
  flex: 0.3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
}
